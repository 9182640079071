/* GRID LAYOUT */

.container {
  position: relative;
  margin: 0 auto;
}

.col1 .row {
  width: 89px;
}
.col2 .row {
  width: 178px;
}
.col3 .row {
  width: 267px;
}
.col4 .row {
  width: 356px;
}
.col5 .row {
  width: 445px;
}
.col6 .row {
  width: 534px;
}
.col7 .row {
  width: 623px;
}
.col8 .row {
  width: 712px;
}
.col9 .row {
  width: 801px;
}
.col10 .row {
  width: 890px;
}
.col11 .row {
  width: 979px;
}
.col12 .row {
  width: 1068px;
}

.offset1 {
  margin-left: 89px;
}

.offset8 {
  margin-left: 712px;
}

/* last column no-margin */
.col1.last,
.col2.last,
.col3.last,
.col4.last,
.col5.last,
.col6.last,
.col7.last,
.col8.last,
.col9.last,
.col10.last,
.col11.last,
.col12.last {
  margin-right: 0px;
}

/* clearfix */

.clearfix:after,
.row:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix,
.row {
  display: inline-block;
} /*Those two .clearfix have to be separate!)*/
.clearfix,
.row {
  display: block;
}
/**/

.fullwidth-container-flex {
  display: flex;
  width: 100%;

  @include mobile {
    width: initial;
  }
}

.header-flex {
  display: flex;
  padding: 30px 0;
  background: $color-midblue;

  @include mobile {
    padding: 0;
  }
}

.navheader-flex-inner {
  & {
    justify-content: space-between;
    align-items: center;
    display: flex;
    }

  @include mobile {
    flex-direction: row !important;
    padding: 20px 30px !important;
  }
}

.navcontainer-flex {
  & {
    display: flex;
    width: 100%;
    /*padding: 0 150px;*/
    border-bottom: 1px solid $color-border-primary;
    /*	margin-right: -150px;
	  margin-left: -150px;*/
  }

  @include mobile {
    &[aria-expanded='true'] {
      border-bottom: 2px solid $color-linkblue;
    }
    border-bottom: none;
  }
}

.startcontainer-flex {
  display: flex;
  padding: 20px 0;

  @include tablet {
      flex-direction: row !important;
  }

  @include mobile {
    flex-direction: column;
    margin-top: 20px;
  }
}

.wrap-flex {
  width: 1240px;
  margin-right: auto;
  margin-left: auto;

  @include mobile {
    width: 100%;
  }

  @include tablet {
    width: 970px;
  }
}

.container-flex {
  width: 1240px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  @include mobile {
    padding: 20px 0;
    width: 100%;
    flex-direction: column;
  }
  @include tablet {
    width: 970px;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.f1 {
  width: 10%;
}

.f2 {
  width: 20%;
}
.f3 {
  width: 30%;
}
.f4 {
  width: 40%;
}
.f5 {
  width: 50%;
}
.f6 {
  width: 60%;
}
.f7 {
  width: 70%;
}
.f8 {
  width: 80%;
}
.f9 {
  width: 90%;
}
.f10 {
  width: 100%;
}

.contentrow-flex {
  display: flex;
  width: 100%;

  @include mobile {
    flex-direction: column;
  }
}

.startcontainer-box {
    width: 50%;
    display: flex;
    align-items: center;


    &__textarea {
        width: 40%;

        @include mobile {
            width: 100%;

            h1 {
                margin-top: 60px;
            }

            h1, h2, h3, h4, p {
                padding: 0 30px;
            }

            & .highlight {
                margin: 0 30px !important;
            }
        }
    }

    @include mobile {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: unset;
    }

    & img {
        width: 620px;
        height: 411px;

        @include tablet {
            width: 485px;
            height: 288px;
       
        }

        @include mobile {
            width: initial;
            height: 249px;
            padding: 0 30px;
            object-fit: contain;
        }
    }
}

.searchcontainer {
  padding: 20px;
  display: flex;
  border-bottom: 2px solid $color-linkblue;

  @include mobile {
    padding: 0 20px;
  }
}

.mobilemenu-container,
.mobilemenu-search {
  display: none;
}

.mobile {
  display: none !important;

  @include mobile {
    display: flex !important;
  }
}
.desktop {
  display: flex !important;

  @include mobile {
    display: none !important;
  }
}

// Mobile exclusive classes

@include mobile {
  .startcontainer-box p,
  .startcontainer-box h1 {
    padding: 0 30px;
  }

  .navheader-flex {
    display: none;
  }

  .mobilemenu-container {
    display: inline-block;
    cursor: pointer;
  }

  .mobilemenu-search {
    display: block;
    margin-right: 15px;
    width: 19px;
    height: 20px;
    & img {
      height: 21px;
      width: 21px;
    }
  }

  //Hamburger menu
  .bar1,
  .bar2,
  .bar3 {
    border-radius: 11px;
    width: 21px;
    height: 4px;
    background-color: $color-linkblue;
    margin: 2.3px 0;
    transition: 0.4s;
  }

  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-4px, 4px);
    transform: rotate(-45deg) translate(-4px, 4px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -5px);
    transform: rotate(45deg) translate(-5px, -5px);
  }

  .topnavigationlinks ul {
    display: none;
  }

  .topnavigationlinks {
    display: flex;
  }
}
