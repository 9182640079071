.small_block {
  margin-bottom: 28px;
  height: 100%;
}

.block_two_wrapper {
  box-sizing: border-box;
  width: 50%;
}
.block_two_wrapper:first-of-type {
  box-sizing: border-box;
  width: 50%;
  padding-right: 30px;
}

.block_two .small_block {
  margin-bottom: 0px;
  border: 1px solid $color-border-primary;
  background: white;
}

.teaser_block {
  border: none !important;
}

.block_three_wrapper {
    box-sizing: border-box;
    width: 393px;

    img {
        width: 100%;

        @include tablet {
          
            height: 200px;
        }
    }
}
.block_three_container {
  display: flex;
}

.block_three_wrapper:nth-of-type(-n + 2) {
  margin-right: 30px;
}

.block_three .small_block {
  margin-bottom: 0px;
  border: 1px solid $color-border-primary;
  background: white;
}

.block_two .small_block.last,
.block_three .small_block.last {
  margin-right: 0px;
}

@media only screen and (min-width: 200px) and (max-width: 1124px) {
  .block_three_container {
    flex-direction: column;
  }
  .block_two_wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 30px;
  }

  .block_three_wrapper {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 30px;
  }

  .block_two .small_block:last-of-type {
    margin-bottom: 0;
  }

  .block_three .small_block:last-of-type {
    margin-bottom: 0;
  }

  .block_two_wrapper:first-of-type {
    padding: 15px 30px;
    width: 100%;
  }
}

/* BLOCK_AREA */

.big_block_area {
  padding: 50px 0px;
  font-size: 16px;
}

.big_block_area.last_block {
  padding-bottom: 80px;
}

.big_block_area .row {
  margin-top: 20px;
}

@media only screen and (min-width: 200px) and (max-width: 1124px) {
  .list_block_mobilemenu_link {
    display: flex;
    justify-content: flex-end;

    & ul li {
      margin: 0 !important;
    }

    & a {
      padding: 30px !important;
    }
  }
  .list_block_mobilemenu_link a {
    font-size: 16px !important;
  }
  .small_block.list_block_mobilemenu h1 {
    padding: 0 30px;
  }
  .big_block_area {
    padding: 0;
  }
}

/* TEXT_BLOCK */
.article_content_menu .text_area {
/*  padding: 0 !important;*/
  a {
    padding: 0 !important;
  }
}
.article_content_menu .img_text_block {
  a {
    padding: 0 !important;
  }
}

.text_area {
  text-align: left;
}

.small_block.text_block .text_area {
  padding: 20px;
}

.small_block.text_block .text_area p {
  margin: 0px 0px 10px 0px;
}

/* IMAGE_TEXT_BLOCK */
.small_block.img_text_block .img_area {
  overflow: hidden;
  max-height: 200px;
}

.small_block.img_text_block .text_area {
  padding: 30px;
}

.small_block.img_text_block .text_area h4 {
  margin-top: 0px;
}

.small_block.img_text_block .text_area p {
  margin: 0px 0px 10px 0px;
}

.small_block.img_text_block a {
  display: block;
  color: $color-darkgray;
}

.img_text_block {
    background: $color-highlight;
}

.small_block.img_text_block a:hover {
    color: $color-darkgray;
    text-decoration: none;
}

.small_block.img_text_block a h4 {
  color: $color-darkblue;
}

.small_block.img_text_block a:hover h3 {
  text-decoration: underline;
}

.small_block.img_text_block a:hover h4:after {
  color: $color-inverted;
}

@media only screen and (min-width: 200px) and (max-width: 1124px) {
  .small_block.text_block .text_area {
    padding: 20px;
  }
}

/* LIST_BLOCK */

.small_block.list_block h4 {
  border-bottom: solid 2px $color-linkblue;
  position: relative;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 22px 0 20px;
  line-height: 20px;
  z-index: 900;
  text-transform: uppercase;
}

.small_block.list_block h4:after,
.small_block.list_block h4:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
}

.small_block.list_block h4:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: $color-white;
  border-width: 8px;
  margin-left: -8px;
}

.small_block.list_block.bg_grey h4:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: $color-border-primary;
  border-width: 8px;
  margin-left: -8px;
}

.small_block.list_block h4:before {
  border-color: rgba(63, 113, 164, 0);
  border-top-color: $color-linkblue;
  border-width: 11px;
  margin-left: -11px;
}

.small_block.list_block ul {
  list-style-type: none;
  padding: 0 20px;
  margin: 0px 0px 0px 0px;


}

.small_block.list_block_mobilemenu ul {
  text-align: center;
  padding: 0 30px;
}
.small_block.list_block_mobilemenu {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
}

.link_block_list {
    position: relative;
    img {
        position: absolute;
        right: 0;
        top: 15px;
        height: 20px;
        width: 21px !important;
    }
}

.list_block_mobilemenu {
  @include mobile {
    & ul li:first-of-type a {
      border-top: 1px solid $color-border-primary;
      padding: 15px 10px !important;
    }
    & ul li:last-of-type a {
      border-bottom: 1px solid $color-border-primary;
      padding: 15px 10px !important;
    }

    @include mobile {
    }
  }
}

.small_block.list_block_mobilemenu a {
  padding: 10px;
  font-size: 18px;
  color: $color-linkblue;
}
.small_block.list_block_mobilemenu a:hover {
  background: none !important;
}
.small_block.list_block li:first-of-type {
    padding: 14px 0 5px;

    & img {
        position: absolute;
        right: 0;
        top: 30px;
        height: 20px;
        width: 21px !important;
    }
}
.small_block.list_block li:last-of-type {
  padding: 5px 0 14px;
}

.small_block.list_block li {
  border-bottom: 1px solid $color-border-primary;
  margin: 0 !important;
  padding: 5px 0;
  list-style-type: none;
  background-image: none !important;
}

.page .article .main_content .small_block.list_block li {
  display: block;
}

.page .article .main_content .small_block.list_block li:before {
  content: '';
  margin: 0px;
  font-family: 'psnicon';
  color: $color-linkblue;
  font-size: 13px;
  display: none;
  text-align: right;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}

.small_block.list_block li .icon {
  font-family: 'psnicon';
  font-size: 13px;
  height: 22px;
  display: inline-block;
}

.small_block.list_block li:last-child {
  border-bottom: 0px solid $color-border-primary;
}

.small_block.list_block li:first-of-type a {
  display: block;
  padding: 30px 10px 15px;
  line-height: 20px;
  position: relative;
  margin: -13px 0 -5px;
}
.small_block.list_block li:last-of-type a {
  display: block;
  padding: 15px 10px 30px;
  line-height: 20px;
  position: relative;
  margin: -5px 0 -13px;
}

.small_block.list_block a {
  display: block;
  padding: 15px 10px;
  line-height: 20px;
  position: relative;
  margin: -5px 0;
  text-align: center;
}

@media (min-width: 1378px) {
  .list_block_mobilemenu {
    display: none;
  }
}

.teaser_block_article {
  display: flex;
  align-items: center;
}

.info-block {
    & p a {
        display: inline !important;
    }
}