html,
body {
  height: 100%;
  width: 100%;
  min-height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  min-height: 100%;
  background: $color-white;
}

.print {
  display: none;
  @include print {
    display: block;
  }
}


.modal-open {
    overflow: hidden; 
}

.blur {
    filter: blur(4px);
}
/* 
    Klass som ligger mellan alla artiklar
    Det är denna diven användaren kommer till när sidan scrollar
   */
.locator {
    height: 0px;
    display: block;
    clear: both;
    position: relative;
    top: -57px;
}
.highlight {
    padding: 20px;
    background: $color-highlight;


    @include mobile {
        padding: 20px !important;
     /*   margin: 0 30px;*/
    }
}
.hide {
  display: none;
}

.wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
}

.backlink {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    margin-left: 20px;
    position: relative;

    @include mobile {
        margin-bottom: -40px;
        margin-top: 60px;
    }

    &__standardpage {
          text-transform: uppercase;
          font-weight: bold;
          text-decoration: none;
          margin-left: 20px;
          position: relative;

        @include mobile {
            margin-bottom: 0;
            margin-top: 0;
        }

        &:before {
            width: 7px;
            height: 7px;
            position: absolute;
            left: -18px;
            top: 5px;
            content: ' ';
            border-top: 2px solid $color-linkblue;
            border-left: 2px solid $color-linkblue;
            transform: rotate(-45deg);
        }
    }

    &:before {
        width: 7px;
        height: 7px;
        position: absolute;
        left: -18px;
        top: 5px;
        content: ' ';
        border-top: 2px solid $color-linkblue;
        border-left: 2px solid $color-linkblue;
        transform: rotate(-45deg);
    }
}

.printlink {
  float: right;
  @include mobile {
    float: unset;
  }
}
/* HEADER */

.header_wrap {
  top: 0px;
  background: $color-white;
  z-index: 9999;
  width: 100%;

  @include mobile {
    position: fixed;
  }
}

/* GLOBAL SEARCH */
.header_wrap .search_box {
  width: 100%;
  background: $color-midblue;
  margin-top: -104px;
  position: relative;

  @include mobile {
    margin-top: -182px;
  }
}

.header_wrap .search_box input[type='search'] {
  font-size: 18px;
  border: 1px solid $color-darkblue;
  background: $color-white;
  height: 60px;
  width: 86%;
  border-radius: 50px;
  margin-right: 20px;
  padding: 0px 10px 0px 50px;
  background: $color-white url(../Images/search-psn.svg) no-repeat 19px 19px;
  background-size: 20px 20px;
  -moz-background-size: 20px 20px;
  -webkit-background-size: 20px 20px;
  -o-background-size: 20px 20px;

  &::-webkit-search-cancel-button {
    display: none;
  }

  @include mobile {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 46px;
  }

  @include tablet {
    width: 82%;
  }
}

.header_wrap .search_box input[type='submit'] {
  padding: 0px 30px;
  background: $color-linkblue;
  border: 1px solid $color-white;
  height: 60px;
  border-radius: 50px;
  color: $color-white;
  width: 12%;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  transition: all .2s ease-in-out;
  @include mobile {
    width: 100%;
  }

  @include tablet {
    width: 15%;
  }
}

.header_wrap .search_box input[type='submit']:hover {
    background: $color-darkblue;
    color: $color-white;
    transition: all .2s ease-in-out;
}

.header_wrap .search_box .search_box_bottom {
  height: 11px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid $color-white;
}

.header_wrap .header {
  border-bottom: 1px solid $color-border-primary;
  height: 120px;
}

.header_wrap .logotype h1 {
  font-size: 20px;
  line-height: 20px;
}

.logotype {
    @include mobile {
        padding-right: 20px;
    }
}

.header_wrap .logotype img {
  width: 390px;
  height: 30px;
  @include mobile {
    width: 100%;
    height: 100%;
  }
}

.header_wrap .logotype span.textlogo {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.8em;
  font-weight: bold;
  position: relative;
  top: 15px;
}

.header_wrap.small .logotype span.textlogo {
  font-size: 1.6em;
  position: relative;
  top: 0;
}

/* MOBILE MENU */
* {
  margin: 0;
  padding: 0;
}

/* Icon 1 */
@include mobile {
  #nav-icon1,
  #nav-icon2,
  #nav-icon3,
  #nav-icon4 {
    width: 24px;
    height: 17px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon1 span,
  #nav-icon3 span,
  #nav-icon4 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $color-linkblue;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  /* Icon 3 */

  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 7px;
  }

  #nav-icon3 span:nth-child(4) {
    top: 14px;
  }

  #nav-icon3.open span:nth-child(1) {
    top: 7px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
  }
}

/* SMALL HEADER */

.header_wrap.small {
  position: fixed;
}

.header_wrap.small .header {
  height: 49px;
}

.header_wrap.small .logotype {
  margin-top: 13px;
}

.header_wrap.small .logotype img {
  /*    width: 80%;*/
}

.header_wrap.small nav.global {
}

.header_wrap.small nav.top {
  margin-top: 8px;
}
@include mobile {
  .quicksearch {
    top: 28px;
    position: absolute;
    content: '';
    width: 21px;
    width: 34px;
    right: 15px;
    height: 42px;

    display: flex;
    justify-content: center;
    align-items: center;

    &__cross-1 {
      top: 19px;
      position: absolute;
      transform: rotate(45deg);
      border-radius: 50px;
      background-color: $color-darkgray;
      height: 4px;
      width: 21px;
    }
    &__cross-2 {
      position: absolute;
      transform: rotate(-45deg);
      border-radius: 50px;
      background-color: $color-darkgray;
      height: 4px;
      width: 21px;
    }
  }
}

.page-wrap {
  min-height: calc(100vh - 58px);
}
/* FOOTER */

.footer_wrap {
  position: relative;
  margin: 0 auto 0 auto;
  color: $color-white;
  min-height: 115px;
  padding: 0px 0px 0px 0px;
  background: $color-blue;
  min-height: 350px;
}

.footer_wrap h4 {
  font-size: 16px;
}
.footer_wrap p {
  font-size: 15px;
  line-height: 20px;
}

.footer_wrap .information {
  padding: 35px 0px 35px 0px;
}

.footer_wrap .information .contact_info {
  padding: 20px 0px 0px 0px;
  font-size: 16px;
  line-height: 22px;
}

.footer_wrap .information .contact_info p {
  margin-bottom: 5px;
}

.footer_wrap .information .contact_info a,
.footer_wrap .information .contact_info a:hover {
  color: $color-white;
  text-decoration: underline;
}

.footer_wrap .information .contact_info a:hover {
  text-shadow: 0 0 2px $color-textshadow;
}

.footer_wrap .information h3 {
  color: $color-white;
  margin-bottom: 10px;
}

.footer_wrap .information p {
  margin-bottom: 5px;
}

.footer_wrap .logotype {
  height: 90px;
  border-top: 1px solid $color-white;
}

.footer_wrap img {
  float: right;
  margin-bottom: 0px;
  width: 100%;
  height: 100%;
  margin-top: 32px;
}

.footer_wrap .logotext {
  margin-top: 32px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.6em;
  font-weight: bold;
  display: inline-block;
}
/* GENERAL ALL PAGES */

.page {
  padding: 60px 0px 30px 0px;
}

.page ul {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.page ul li {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.page .highlight {
  padding: 21px 22px 20px 22px;
  margin: 0px 0px 30px 0px;
  font: 14px/22px 'Open Sans', sans-serif;
  font-weight: 300;
}

.page .highlight p {
  margin: 0px 0px 10px 0px;
}

.page .highlight h5,
.page .highlight h6 {
  margin-bottom: 10px;
}

.page.bg_grey .highlight {
  background: $color-white;
}

.page.bg_white .highlight {
  background: $color-midblue; /*old grey: #F6F8FB*/
}

/* STARTPAGE */

.text_area {
  & img {
    width: 100%;
    padding: 0;
  }

  & p {
    font-size: 17px;
    line-height: 23px;
    @include mobile {
      font-size: 16px;
    }
  }
}

.main_start {
  position: relative;
}

.main_start .text_area {
  background: none;
}

.main_start .img_area {
  float: left;
  position: absolute;
  bottom: -40px;
  right: 0;
  z-index: 1000;
  overflow: hidden;
}
.img_area {
    img {
        width: 100%;
        height: 200px;
    }

    &__small_block {
        max-height: 200px;
    }
}
/* COLLECTIONPAGE & ARTICLEPAGE */
.main_collection .text_area {
    background: $color-highlight;
}

.main_collection .link_block_nav {
    padding: 10px 0;
}

.main_collection .text_area h2 {
  padding-top: 4px;
}

.article {
  position: relative;
}

.article .row {
  position: relative;
}

.navigate_to_top {
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 306px;
    text-align: center;
    background: none;
    border-top: 1px solid $color-linkblue;
    padding-top: 12px;

    @include mobile {
        left: 0;
        bottom: -40px;
        width: 100%;
    }
}

.navigate_to_top:after,
.navigate_to_top:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.bg_grey .navigate_to_top:after {
  border-color: rgba(213, 213, 213, 0);
  border-bottom-color: $color-border-primary;
  border-width: 8px;
  margin-left: -8px;
}

.bg_white .navigate_to_top:after {
  border-color: rgba(213, 213, 213, 0);
  border-bottom-color: $color-white;
  border-width: 8px;
  margin-left: -8px;
}

.navigate_to_top:before {
  border-color: rgba(246, 248, 251, 0);
  border-bottom-color: $color-linkblue;
  border-width: 11px;
  margin-left: -11px;
}

.navigate_to_top a {
  text-decoration: none;
}

.navigate_to_top a:hover {
  text-decoration: underline;
  color: $color-linkblue;
}

@media (hover: hover) {
  .main_content_container:hover {
    background: $color-backgroundgray;
    cursor: pointer;
  }
}

.main_content[aria-expanded='true'] {
  /*  transition: all 0.3s ease-in-out;*/
  background-color: $color-backgroundgray;
  padding: 0 25px 25px 25px;
}

.main_content[aria-expanded='false'] {
  /*  transition: all 0.3s ease-in-out;*/
  padding: 0;
}

.main_content_wrapper {
  padding-left: 10px;

  overflow: hidden;
  transition: height 0.2s ease-in-out;
}

.contentHeight {
  height: 92px !important;
}

.showContent {
  height: 500px;
  overflow: auto;
  transition: height 0.2s ease-in-out;
}

.main_content {
  max-width: 800px;
  transition: max-width 0.2s ease-in-out;
}

.main_content_expanded {
  border-top: none;
  max-width: 100%;
  transition: max-width 0.2s ease-in-out;
}

.page .article ul {
  margin-bottom: 25px;
  margin-left: 35px;
}

.page .article li {
  list-style-type: square;
  margin-bottom: 5px;
}

.page .article .main_content h3 {
  padding-top: 9px;
}

.page .article .main_content ul {
  margin-left: 0px;
}

.page .article .main_content li {
  list-style-type: none;
  display: table-row;
}

.page .article .main_content .list_block li {
  list-style-type: none;
  display: table-row;
}

.page .article .main_content li:before {
  font-size: 13px;
  display: table-cell;
  text-align: right;
  padding-left: 22px;
  padding-right: 15px;
  padding-top: 8px;
}

.page .article .main_content li {
  display: block;
  padding-left: 25px;
  margin-left: 25px;
  background-image: url('../Images/list-icon-blue.png');
  background-repeat: no-repeat;
  background-position: 0px 8px;
}

.page .article .main_content li:first-child:before {
  padding-top: 0px;
}

.bg_white .main_content img {
  background: $color-white;
  margin: 0px auto;
  display: block;
  padding: 10px;
}

.bg_grey .main_content img {
  background: $color-white;
  margin: 0px auto;
  display: block;
  padding: 10px;
}

.main_content img.no_border {
  margin: 0px auto;
  display: block;
  padding: 0px;

  height: 200px;

  @include mobile {
      width: 100%;
  }
}

.hide_article_content {
  height: 0;
}

.article_publishInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  border-top: 1px solid $color-border-primary;
  padding: 20px 0;
  @include mobile {
    flex-direction: column;
    align-items: start;
    div:first-of-type {
      margin-bottom: 10px;
    }
  }
}

.article_content_menu {
  padding: 0 0 20px 50px;
  overflow: hidden;
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  -o-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
  cursor: default;

  @include mobile {
    padding: 0;
  }
}

.article_content_paragraph {
    min-width: 660px;
    max-width: 660px;
    padding-right: 30px;

    @include mobile {
        min-width: unset;
        max-width: unset;
        padding-right: 0;
    }

    ul {
        list-style-type: disc !important;

        li {
            list-style-type: disc !important;
            border: none !important;
        }
    }

    a {
        padding: 0 !important;
        display: unset !important;
    }
}

// .article_content_menu a {
//   padding: 0 !important;
// }
.article_content_menu_aside {
    border-top: 1px solid $color-lightgray;
    padding: 30px 0;
    min-width: 660px;
    max-width: 660px;

    img {
        width: 660px !important;
    }

    @include tablet {

        img {
            width: 100% !important;
        }
    }

    .text_area {
        margin-top: 20px;
    }

    @include mobile {
        width: 100% !important;
        margin: 0;
        padding: 40px 0 0 0;
        border-left: none;
        min-width: unset;
        max-width: unset;


        img {
            width: 100% !important;
        }
    }
}

.summary-page {
  margin-top: 40px;
}
.article_content_menu_header {
    margin: 0 0 30px 0;

    img {
        object-fit: cover;
        width: 1240px;
        height: 314px;

        @include mobile {
            width: 100%;
            height: 249px;

        }

        @include tablet {
            width: 970px;
            height: 314px;
        }
    }
}

.article_content_menu[aria-expanded='true'] > .article_collapse_arrow {
  transform: rotate(90deg);
}

.noHeight {
  padding: 0px 50px !important;
  height: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.summary_article {
  margin-bottom: 40px;
}
.related_articles {
  text-transform: uppercase;
  color: $color-darkgray;
  margin-top: 30px;
  @include mobile {
    margin-bottom: -20px;
  }
}

.article_collapse {
  padding: 20px 0;
  position: relative;
  display: flex;
  align-items: center;
  h3 {
    margin-bottom: 0;

    &:focus {
      text-decoration: underline;
    }
  }

  img {
    margin-right: 20px;
    transform: rotate(0deg);
    width: unset !important;
  }
}

.article_collapse_circle {
  position: absolute;
  height: 25px;
  width: 25px;
  border: 1px solid $color-border-primary;
  border-radius: 100px;
  content: '';
  left: 25px;
  top: 14px;
}

.article_collapse_arrow {
  position: absolute;
  margin-right: auto;
  left: 34px;
  right: 0;
  top: 21px;
  text-align: center;
  display: flex;
  border: solid $color-darkblue;
  border-width: 0 1px 1px 0;
  width: 3px;
  height: 3px;
  padding: 3px;
  transform: rotate(45deg);
  margin-right: 5px;
}

.article_collapse_circle:hover {
  cursor: pointer;
}

.article_collapse h3:hover {
  text-decoration: underline;
  cursor: pointer;
}


.article_collapse_circle_footer {
    position: absolute;
    height: 25px;
    width: 25px;
    border: 1px solid $color-white;
    border-radius: 100px;
    content: '';
    left: 25px;
    top: 14px;
}

.article_collapse_arrow_footer {
    position: absolute;
    margin-right: auto;
    left: 34px;
    right: 0;
    top: 21px;
    text-align: center;
    display: flex;
    border: solid $color-white;
    border-width: 0 1px 1px 0;
    width: 3px;
    height: 3px;
    padding: 3px;
    transform: rotate(45deg);
    margin-right: 5px;
}

.article_collapse_footer {
    padding: 10px 75px;
    position: relative;
}

.article_collapse_footer h3 {
    margin: 0;
}

.article_content_menu_body {
    // & ul li::before {
    //   content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    //   color: $color-linkblue; /* Change the color */
    //   font-weight: bold; /* If you want it to be bold */
    //   display: inline-block; /* Needed to add space between the bullet and the text */
    //   width: 1em; /* Also needed for space (tweak if needed) */
    //   margin-left: -1em; /* Also needed for space (tweak if needed) */
    // }
    h1 {
        word-break: break-word;
    }

    &__header {
        max-width: 1000px;
    }

    &__headerIngress {
        max-width: 1000px;
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        color: #454545;
        font-weight: 900;
        font-family: AvenirNextLTProDemi !important;
    }

    & ol li::marker {
        font-weight: bold;
        color: $color-linkblue; /* Change the color */
    }

    @include mobile {
        margin-top: 20px;

        & img {
            width: 100%;
        }
    }
}

    .article_content_menu_body p {
        max-width: 800px;

        @include mobile {
            max-width: unset;
        }
    }
    /* LIST ARTICLE */

    .listarticles_wrapper {
        @include mobile {
            margin-top: 20px;
        }
    }

    .listarticle_form {
        padding: 0 30px;
    }

    .listarticles_wrapper h1 {
        margin: 40px 0;

        @include mobile {
            margin: 60px 0 40px 0;
        }
    }

    .listarticles_wrapper h1,
    .summary_findarticle {
        @include mobile {
            padding: 0 30px;
        }
    }

.listarticle_container {
    border-color: $color-border-primary;
    border-style: solid;
    border-width: 0 0 1px 0;
    padding: 20px 0;
    display: flex;
    align-items: flex-start;




    @include mobile {
        cursor: pointer;
        flex-direction: column;
        align-items: flex-start;
    }
}

    .listarticle_container:first-of-type {
        border-color: $color-border-primary;
        border-style: solid;
        border-width: 1px 0 1px 0;
        padding: 20px 0;
    }

    .listarticle_container img {
        margin-right: 20px;
        width: 200px;
        min-width: 200px;
        height: 136px;

        @include mobile {
            height: auto;
            width: 100%;
            max-height: 220px;
            margin-bottom: 20px;
        }
    }

    .listarticle_pagenation {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;

        & div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            width: 33%;

            & p {
                margin: 0;
            }
        }

        & div:last-of-type {
            justify-content: flex-end;
        }

        & div:first-of-type {
            justify-content: flex-start;
        }
    }

    .listarticle_body {
        & p {
            max-width: 1000px;
            max-height: 80px;
        }

        @include mobile {
            max-height: 300px;

            & span {
                display: none;
            }
        }
    }

    .listarticle_pagenation .listarticle_seemore {
        padding: 20px;
    }

    .listarticle_seemore button {
        margin: 20px;
        font-size: 18px;
        height: 55px;
        width: 100%;
        padding: 10px 20px;
        font-weight: 600;
        border-radius: 50px;
        border: 1px solid $color-border-primary;
        color: $color-linkblue;
        background: white;
        transition: all 0.2s ease-in-out;
    }

    .search_area {
        width: 100%;
        padding: $base-verticalpadding;

        @include mobile {
            position: relative;
            margin-top: 20px;
        }
    }

    .search_area_cross {
        top: 39px;
    }

    .search_area input[type='search'] {
        font-size: 18px;
        border: 1px solid $color-border-primary;
        background: $color-white;
        height: 60px;
        width: 86%;
        border-radius: 50px;
        margin-right: 20px;
        padding: 0px 10px 0px 50px;
        background: $color-white url(../Images/search-psn.svg) no-repeat 19px 19px;
        background-size: 20px 20px;
        -moz-background-size: 20px 20px;
        -webkit-background-size: 20px 20px;
        -o-background-size: 20px 20px;

        &::-webkit-search-cancel-button {
            display: none;
        }

        @include tablet {
            width: 85%;
        }

        @include mobile {
            width: 100%;
            margin-bottom: 20px;
            padding-right: 46px;
        }
    }

    .search_area input[type='submit'] {
        padding: 0px 30px;
        background: $color-linkblue;
        border: 1px solid $color-white;
        height: 60px;
        border-radius: 50px;
        color: $color-white;
        width: 12%;
        font-size: 18px;
        cursor: pointer;

        @include mobile {
            width: 100%;
        }
    }

    .search_area input[type='submit']:hover {
        background: $color-darkblue;
        color: $color-white;
    }

    .search_area input[type='submit'],
    x:-moz-any-link,
    x:default {
        padding: 6px 30px 6px 30px;
    }

    .search {
        position: relative;
    }

    .search_result {
        margin-bottom: 10px;
    }

    .search_result .result_no_hits {
        border-bottom: 1px solid $color-linkblue;
        padding-bottom: 6px;
    }

    .search_result .result_hits {
        position: relative;
        border-bottom: 1px solid $color-border-primary;
        padding-bottom: 20px;
    }

    .page .search_result li {
        list-style-type: none;
        padding: 22px 20px 23px 20px;
        background: $color-white;
        border-bottom: 1px solid $color-border-primary;
        font: 14px/22px 'Open Sans', sans-serif;
        font-weight: 300;
        max-height: 120px;
    }

    .page .search_result li a[href*='.pdf']:after {
        content: '\65';
        display: inline-block;
        float: right;
        right: 18px;
        position: absolute;
        font-family: 'lmsnicon';
        font-size: 22px;
        font-style: normal;
    }

    .page .search_result li:last-child {
        border-bottom: 0px solid $color-border-primary;
    }

    .page .search_result li h6 {
        margin-bottom: 5px;
    }

    .page .search_result li a {
        text-decoration: none;
    }

    .page .search_result li a:hover {
        text-decoration: underline;
    }

    .page .search_result li a .icon {
        font-family: 'lmsnicon';
        display: inline-block;
        color: $color-linkblue;
        font-size: 14px;
    }

    .page .search_result li a:hover .icon {
        text-decoration: underline;
        color: $color-darkblue;
    }
    /* EPISERVER - START */
    #epi-quickNavigator li {
        margin-left: 0px;
        padding: 0px;
    }

    #epi-quickNavigator ul {
        margin: 0px;
    }

    .epi-quickNavigator-editLink li:before {
        content: none;
    }
    /* EPISERVER - END */
    /* COLOR CLASSES */

    .bg_blue {
        background: $color-linkblue;
    }

    .bg_blue h3,
    .bg_blue p,
    .bg_green h3,
    .bg_green p {
        color: $color-white;
    }

    .bg_green {
        background: $color-inverted;
    }

    .bg_grey {
        background: $color-backgroundgray;
    }

    .bg_white {
        background: $color-white;
    }

    .bg_standard {
        background: $color-blue;
    }

    .bg_standardlight {
        background: #83b3d3;
    }
    /* EPISERVER - START */
    #epi-quickNavigator li {
        margin-left: 0px;
        padding: 0px;
    }

    #epi-quickNavigator ul {
        margin: 0px;
    }

    .epi-quickNavigator-editLink li:before {
        content: none;
    }
    /* EPISERVER - END */
    /* ANIMATIONS */
    .header_animation {
        padding: 40px 0 12px;
        overflow: hidden;
    }

    .article-background-wrapper {
        margin: 0;
        background: $color-backgroundgray;
        transition: all 0.2s ease-in-out;
        display: flex;

        @include mobile {
            flex-direction: column;
            margin: 0 -30px;
            padding: 0 30px;
        }
    }

    .article-transition {
        transition: all 0.2s ease-in-out;
    }

    .image-teasertext {
        display: flex;
        align-items: center;

        & p {
            font-style: italic;
            font-style: 15px;
            line-height: 20px;
            margin: 0 15px 0 0;
        }
    }
    // Sharebar
    .sharebar {
        float: right;
        position: relative;
        display: inline-block;

        @include mobile {
            margin-bottom: rem-calc(20);
        }

        &__box {
            background: white;
            border-width: 1px 0 1px 0;
            border-style: solid;
            border-color: $color-border-primary;
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            margin-left: rem-calc(-1);
            transition: width 0.2s ease-in-out, border-width 0.2s ease-in-out;
            width: 0;
            overflow: hidden;
            margin-top: 1px;
            margin-left: -1px;
            height: 56px;

            @media screen and (min-width: 1123px) and (max-width: 1800px) {
                left: 0;
                transition: width 0.2s ease-in-out, border-width 0.2s ease-in-out, left 0.2s ease-in-out;
            }

            &:hover {
                #sharebutton {
                    transition: all 0.2s ease-in-out;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            .sharebar:hover & {
                width: 220px;
                transition: width 0.2s ease-in-out, border-width 0.2s ease-in-out;
                border-width: 1px 1px 1px 0;
                border-style: solid;
                border-color: $color-border-primary;

                @media screen and (min-width: 1123px) and (max-width: 1800px) {
                    border-width: 1px 0 1px 1px;
                    left: -220px;
                    transition: width 0.2s ease-in-out, border-width 0.2s ease-in-out, left 0.2s ease-in-out;
                }
            }
        }

        &__button {
            margin-bottom: 0;
            transition: all 0.2s ease-in-out 0.2s;
            width: 100%;

            .sharebar:hover & {
                transition: all 0.2s ease-in-out 0.2s;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                @media screen and (min-width: 1123px) and (max-width: 1800px) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                }
            }
        }

        &__link {
            display: block;
            width: rem-calc(30);
            height: rem-calc(30);
            background-position: center;
            background-size: rem-calc(20 + 20);
            background-repeat: no-repeat;
            margin: rem-calc(5);

            & a {
                display: flex;
            }

            img {
                margin: 15px;
                width: 25px;
            }

            &:hover {
                background-color: $color-backgroundgray;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            height: 100%;
            display: flex;
            align-items: center;
            padding: rem-calc(0 + 5);
        }

        li {
            padding: 0;
            margin-left: 0;

            & a {
                display: flex;
            }
        }
    }