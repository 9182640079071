.header_wrap .search_box input[type='search'] {
  background: #fff url(../Images/search-lmsn.svg) no-repeat 19px 19px;
}


.header_wrap .logotype img {
    width: 500px;
    height: 30px;

    @include mobile {
        width: 100%;
        height: 100%;
    }
}