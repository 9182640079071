// BEGIN COLORS
$color-blue: #499c7e;
$color-midblue: #e6edeb;
$color-linkblue: #107953;
$color-linkblue-disabled: rgba(16, 121, 83, .5);
$color-darkblue: #0b4b34;
$color-darkgray: #454545;
$color-lightgray: #baba;
$color-highlight: #e6edeb;
$color-backgroundgray: #f8f8f8;
$color-inverted: #375e7f;
$color-white: #ffffff;
$color-textshadow: #f0f0f0;
$color-black: #000;

// BORDERS
$color-border-primary: #baba;

// COOKIEBAR
$color-cookie-off: #8e605b;

// END COLORS

// PADDING
$base-padding: 30px;
$base-pagepadding: 0 30px;
$base-verticalpadding: 30px 0;

// PADDING MOBILE
$base-padding-mobile: 20px;
$base-pagepadding-mobile: 0 30px;

// MEDIA QUERIES
$tablet-width: 1124px;
$desktop-width: 1378px;
