.modal-open {
    position: relative;
    overflow-x: hidden;
}

.blur {
    filter: blur(4px);
    -webkit-filter: blur(4px);
    overflow: hidden;
}

.c-cookieModal-hide {
    display: none;
}

.c-cookieModal-display {
    display: flex;
}

.c-cookieModal {
    background: $color-white;
    padding: 20px;
    align-items: center;
    justify-content: center;
    border-top: 2px solid $color-border-primary;
    border-bottom: 2px solid $color-border-primary;
    position: fixed;
    z-index: 10000;
    width: 100%;
    bottom:0;
    @include mobile {
        padding: 0;
    }
}

.c-cookieModal__main {
    overflow: hidden;


    @include mobile {
        height: 378px;
        overflow: auto;
    }

    h4 {
        @include mobile {
            padding: 0 20px;
        }
    }
}

.c-cookieModal__main__button-toggle__label {
    font-size: 16px;
    font-weight: bold;
}

.c-cookieModal__main__show-more {
    display: flex;
    justify-content: center;
    font-weight: bold;
    align-items: center;
    padding: 20px 0 0 0;
    width: 100%;
    opacity: 0.7;
}

.c-cookieModal__main__show-more p {
    margin: 0;
}

.c-cookieModal__main__show-more[aria-pressed='true'] {
    border-bottom: solid 0.5px;
}

.c-cookieModal__main__show-more__preamble {
    text-align: center;
    margin: 30px 0 30px 0;
}

.c-cookieModal__main__show-more__text-icon {
    transform: rotate(90deg);
    transition: all 0.2s ease-in-out;
    font-size: 32px;
    cursor: pointer;
}

.c-cookieModal__main__show-more__text-icon-active {
    transform: rotate(-90deg);
    font-size: 32px;
}

.c-cookieModal__main__show-more__text {
    cursor: pointer;
    margin-right: 5px;
    font-weight: bold;
    font-size: 14px;
}

.c-cookieModal__main__buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.c-cookieModal__main__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @include mobile {
        padding: 0 20px;
    }
}

.c-cookieModal__main__button-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.c-cookieModal__main__button-cta {
    border: none;
    height: 40px;
    width: 150px;
    color: white;
    background: $color-linkblue;
    cursor: pointer;
    font-weight: bold;
}

.c-cookieModal-table {
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
}

.c-cookieModal__main__show-more-expand {
    height: auto;
    max-height: 600px;
    -moz-transition: max-height 0.4s ease-in-out;
    -ms-transition: max-height 0.4s ease-in-out;
    -o-transition: max-height 0.4s ease-in-out;
    transition: max-height 0.4s ease-in-out;
}

.c-cookieModal__main__show-more-expand-tablewrapper {
    height: 300px;
    overflow-x: hidden;
    overflow: scroll;
}

.c-cookieModal__main__show-more-table::-webkit-scrollbar-button {
    display: none;
}

.closed {
    max-height: 0;
    -webkit-transition: max-height 0.4s ease-in-out;
    -moz-transition: max-height 0.4s ease-in-out;
    -ms-transition: max-height 0.4s ease-in-out;
    -o-transition: max-height 0.4s ease-in-out;
    transition: max-height 0.4s ease-in-out;
    overflow: hidden;
}

.--open {
    -webkit-transition: height 0.5s ease-in-out;
    -moz-transition: height 0.5s ease-in-out;
    -ms-transition: height 0.5s ease-in-out;
    -o-transition: height 0.5s ease-in-out;
    transition: height 0.5s ease-in-out;
    height: 400px;
}

.c-cookieModal-table-row {
    text-align: left;
    padding: 10px 5px 10px 15px;
    font-size: 12px;
}

.c-cookieModal-table-head {
    background: $color-white;
    font-size: 14px;
    padding: 5px 0 5px 15px;
    text-align: left;
}

.switch {
    margin-right: 5px;
    position: relative;
    display: inline-block;
    width: 100px;
    height: 32px;
    border: 1px solid $color-border-primary;
    border-radius: 50px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 28px;
    width: 50px;
    left: 47px;
    bottom: 2px;
    background-color: $color-cookie-off;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider-fullWidth {
    border-radius: 50px;
    position: absolute;
    content: '';
    height: 32px;
    width: 100px;
    background-color: $color-linkblue;
}

input:checked + .slider {
    background-color: $color-white;
}

input:focus + .slider {
    box-shadow: 0 0 0.2px $color-white;
}

input:checked + .slider:before {
    background: $color-linkblue;
    -webkit-transform: translateX(-45px);
    -ms-transform: translateX(-45px);
    transform: translateX(-45px);
}

/*------ ADDED CSS ---------*/
.on {
    display: none;
}

.on {
    color: $color-white;
    position: absolute;
    transform: translate(-50%, -50%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    top: 50%;
    left: 27%;
    font-weight: bold;
    font-size: 12px;
}

.on-fullWidth {
    display: block;
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    top: 50%;
    left: 50%;
    font-weight: bold;
    font-size: 12px;
}

.off {
    color: white;
    font-weight: bold;
    position: absolute;
    transform: translate(-50%, -50%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    top: 50%;
    left: 73%;
    font-size: 12px;
}

input:checked + .slider .off {
    display: none;
}

input:checked + .slider .on {
    display: block;
}
/*--------- END --------*/
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider.square {
    border-radius: 50px;
}

.slider.square:before {
    border-radius: 50px;
}

@media only screen and (min-width: 200px) and (max-width: 1124px) {
    .c-cookieModal__main__buttons {
        flex-direction: column;
        justify-content: center;
    }

    .c-cookieModal__main__button-toggle {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .c-cookieModal__main__button-cta {
        width: 100%;
    }
}
