/* GLOBAL NAVIGATION */

.header_wrap nav.global {
  font-size: 14px;

  @include mobile {
    align-items: center;
  }
}

.header_wrap nav.global ul {
  float: right;
  list-style-type: none;
  margin: 0;
  position: relative;
  padding: 0px 0px 0px 0px;
}

.header_wrap nav.global ul.arrow:before {
  top: -39px;
  left: 25px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  border-color: $color-midblue;
  border-top-color: $color-linkblue;
  border-width: 12px;
  margin-left: -6px;
}
.header_wrap nav.global ul.arrow:after {
  top: -45px;
  left: 25px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  border-width: 18px;
  margin-left: -18px;
  border-color: transparent;
  border-top-color: $color-midblue;
  border-width: 15px;
  margin-left: -9px;
}

@media only screen and (min-width: 200px) and (max-width: 1124px) {
  .header_wrap nav.global ul.arrow:before {
    top: -39px;
    left: 25px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    border-color: $color-midblue;
    border-top-color: $color-linkblue;
    border-width: 12px;
    margin-left: -6px;
  }
  .header_wrap nav.global ul.arrow:after {
    top: -45px;
    left: 25px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    border-width: 18px;
    margin-left: -18px;
    border-color: transparent;
    border-top-color: $color-midblue;
    border-width: 15px;
    margin-left: -9px;
  }
}

.header_wrap nav.global li {
  float: left;
  margin-left: 30px;

  &::before {
    content: none;
  }
}

.header_wrap nav.global li:first-child {
  margin-left: 0px;
}

.header_wrap nav.global li a {
  margin-left: 0px;
  text-decoration: none;
}

.header_wrap nav.global li a:hover {
  text-decoration: underline;
}

.header_wrap nav.global li:first-child a {
  margin-left: 10px;
  display: inline-block;
  font-weight: 600;
}

.header_wrap nav.global li:first-child a:before {
  font-family: 'psnicon';
  display: inline-block;
  width: 15px;
  height: 15px;
  content: '\66';
  color: $color-linkblue;
  margin-right: 5px;
  font-size: 16px;
  float: left;
}

.header_wrap nav.global li:first-child a:before:hover {
  color: $color-darkblue;
  text-decoration: none;
}

/* TOP NAVIGATION */

.top ul li {
  a {
    padding: 15px 30px;

    @include tablet {
      padding: 15px 15px;
    }
  }

  &:first-of-type a {
    padding: 15px 30px 15px 0;
    @include tablet {
      padding: 15px 15px 15px 0;
    }
  }
}

.header_wrap nav.top ul {
  list-style-type: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.header_wrap nav.top li {
  float: left;
  padding: 0px 0px 0px 0px;

  &::before {
    content: none;
  }
}

.header_wrap nav.top li:last-child {
  margin: 0px 0px 0px 0px;
}

.header_wrap nav.top li a {
  margin: 0px 0px 0px 0px;
  // padding: 15px 30px;
  display: block;
  text-decoration: none;
}

.header_wrap nav.top li a:hover {
  text-decoration: underline;
}

.header_wrap nav.top li.selected a {
  text-decoration: underline;
}

/* SUB NAVIGATION */

nav.sub {
  color: $color-linkblue;
  margin: 60px 0px 60px 0px;
}

nav.sub h4 {
  color: $color-linkblue;
  border-bottom: solid 1px $color-linkblue;
  position: relative;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 9px 0px;
}

nav ul li {
  margin-left: 0;
}

nav.sub h4:after,
nav.sub h4:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
}

nav.sub h4:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: $color-midblue;
  border-width: 8px;
  margin-left: -8px;
}

nav.sub h4:before {
  border-color: rgba(63, 113, 164, 0);
  border-top-color: $color-linkblue;
  border-width: 11px;
  margin-left: -11px;
}

nav.sub ul {
  list-style-type: none;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

nav.sub li {
  border-bottom: 1px solid $color-border-primary;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

nav.sub li .icon {
  font-family: 'psnicon';
  font-size: 14px;
}

nav.sub li:last-child {
  border-bottom: 0px solid $color-border-primary;
}

nav.sub a {
  display: block;
  padding: 13px 20px 12px 20px;
  text-decoration: none;
  line-height: 20px;
  cursor: pointer;
}

nav.sub a:hover {
  text-decoration: underline;
  background: $color-inverted;
  color: $color-white;
}

nav.sub a:hover .icon {
  text-decoration: none;
}

/* FOOTER NAVIGATION */

.mobile_footer {
  display: none;
}

.desktop_footer {
  display: block;
}

.footer_wrap .information nav {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 0px 20px 0px;
}

.footer_wrap .information nav ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.footer_wrap .information nav ul li {
  padding: 0px;
}

.footer_wrap_flex {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    & li {
        width: 393px;
        margin-bottom: 30px;
        margin-right: 30px;
        @include mobile {
            width: 100%;
            margin-bottom: 10px;
            margin-right: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        @include tablet {
            width: 303px;
        }
    }
}

@media only screen and (min-width: 200px) and (max-width: 1124px) {
  .footer_wrap_flex {
    flex-direction: column;
    padding: 0 30px !important;
    width: unset;
  }
}

.footer_wrap_flex ul li p {
  color: $color-white;
  font-weight: bold;
}

.footer_wrap_flex ul li div {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px;
}

.footer_wrap_title {
    color: $color-white;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer_wrap .information nav ul li ul {
  /*max-width: 129px;*/

}

.footer_wrap .information nav ul li ul li {
  float: none;
  margin: 0px 0px 0px 0px;
  font-size: 15px;
  width: 100%;
}

.footer_wrap .information nav ul li a {
    margin: 0px 0px 0px 0px;
    padding: 0 0 4px 0 !important;
    display: inline-block;
    color: $color-white;
    font-weight: bold;
    text-decoration: none;
    font-size: 15px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.footer_wrap .information nav ul li.selected a {
    color: $color-white;
    font-weight: bold;
    border: 1px solid $color-white;
}

.footer_wrap .information nav ul li.selected ul li a {
    color: $color-white;
    font-weight: bold;
    border: 0px solid $color-white;
}

.footer_wrap .information nav ul li ul li a {
  font-size: 15px;
  line-height: 20px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  /*-ms-word-break: break-all;*/
  padding: 4px 0px 4px 12px;
}

.footer_wrap .information nav ul li ul li a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 200px) and (max-width: 1124px) {
  .footer_wrap .information nav ul li ul {
    /*max-width: 129px;*/
    max-width: unset;
    margin-right: 0;
  }

  .mobile_footer {
    display: block;
  }

  .desktop_footer {
    display: none;
  }
}
@media only screen and (min-width: 1124px) and (max-width: 1378px) {
  // .header_wrap nav.global ul.arrow:before {
  //   top: -285%;
  // }

  // .header_wrap nav.global ul.arrow:after {
  //   top: -291%;
  // }

  .header_wrap nav.top li {
    margin: 0px 10px 0px 0px;
  }
}
