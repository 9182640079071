body:not(.show-focus-outline) * {
outline: none !important;
}

    input[type='button'] {
        padding: 0px 30px;
        background: $color-linkblue;
        border: 1px solid $color-white;
        height: 60px;
        border-radius: 50px;
        color: $color-white;
        width: 170px;
        font-size: 18px;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            background: $color-darkblue;
            color: $color-white;
            transition: all .2s ease-in-out;
        }
    }

    img {
        object-fit: cover;
    }

    button {
        padding: 0px 30px;
        background: $color-linkblue;
        border: 1px solid $color-white;
        height: 60px;
        border-radius: 50px;
        color: $color-white;
        width: 170px;
        font-size: 18px;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            background: $color-darkblue;
            color: $color-white;
            transition: all .2s ease-in-out;
        }
    }
